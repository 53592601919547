<template>
  <div class="page-content">
    <div class="header-box mb-24">
      <div class="left">
        <span>企业：</span>
        <el-select class="mr-20" v-model="queryParams.EnterpriseId" clearable>
          <el-option
            v-for="item in enterpriseList"
            :key="item.Id"
            :label="item.EnterpriseName"
            :value="item.Id"
          ></el-option>
        </el-select>
        <span>岗位：</span>
        <el-select class="mr-20" v-model="queryParams.JobId" clearable>
          <el-option
            v-for="item in jobList"
            :key="item.Id"
            :label="item.Name"
            :value="item.Id"
          ></el-option>
        </el-select>
        <el-button type="primary" @click="initialize">搜索</el-button>
      </div>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      v-loading="loading"
      height="640px"
    >
      <template v-for="(item, index) in tableConfig">
        <el-table-column :key="index" :label="item.label" :prop="item.prop">
          <template slot-scope="scope">
            <span v-if="item.prop === 'Name'">{{
              scope.row.StudentInfo ? scope.row.StudentInfo.Name : ""
            }}</span>
            <div v-else-if="item.prop === 'Time'">
              <span>{{
                filterTimeStr(
                  scope.row.ActualStartTime,
                  scope.row.ActualEndTime
                )
              }}</span>
            </div>
            <el-tag
              v-if="item.prop === 'Status'"
              :type="scope.row.Status | statusFilter"
              >{{ scope.row["Status_V"] }}</el-tag
            >
            <span v-else>{{ scope.row[item.prop] }}</span>
          </template>
        </el-table-column>
      </template>
      <el-table-column label="操作" width="200px">
        <template slot-scope="scoped">
          <el-button
            type="text"
            icon="el-icon-view"
            @click="handleView(scoped.row.Id)"
            >查看轨迹</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { getPageList } from "@/api/internshipImplement";
import { queryApplyJobCatelog } from "@/api/jobApply";
import { getEnterpriseList } from "@/api/internshipLayout";
import contentTools from "@/mixins/content-tools";
import { StaffTypeEnum } from "@/utils/constant";
import { mapGetters } from "vuex";
export default {
  filters: {
    statusFilter(status) {
      const statusMap = {
        "-1": "danger", //被驳回
        "-2": "info", //失效
        0: "success", //正常
        1: "warning", //实践中
        10: "", //实践结束
        11: "" // 归档
      };
      if (statusMap[status]) {
        return statusMap[status];
      } else {
        return "";
      }
    }
  },
  computed: {
    ...mapGetters(["employee"]),
    RoleType() {
      const { matched } = this.$route;
      if (matched.some(({ path }) => path.includes("enterprise"))) {
        return StaffTypeEnum.Enterprise;
      } else if (matched.some(({ path }) => path.includes("school"))) {
        return StaffTypeEnum.School;
      }
      return undefined;
    }
  },
  mixins: [contentTools],
  data() {
    return {
      loading: false,
      queryParams: {
        PageSize: 999,
        PageIndex: 1
      },
      tableData: [],
      tableConfig: [
        {
          label: "学生姓名",
          prop: "Name"
        },
        {
          label: "公司名称",
          prop: "EnterpriseName"
        },
        {
          label: "岗位名称",
          prop: "JobName"
        },
        {
          label: "实践时间",
          prop: "Time"
        },
        {
          label: "状态",
          prop: "Status"
        }
      ],
      jobList: [],
      enterpriseList: []
    };
  },
  async created() {
    await this.initialize();
  },
  methods: {
    async initialize() {
      try {
        this.loading = true;
        if (this.RoleType === StaffTypeEnum.School) {
          let temp = this.employee.find(el => el.StaffType === this.RoleType);
          this.queryParams.SchoolId = temp.Employer.Id;
        } else if (this.RoleType === StaffTypeEnum.Enterprise) {
          let temp = this.employee.find(el => el.StaffType === this.RoleType);
          this.queryParams.EnterpriseId = temp.Employer.Id;
        } else {
          return null;
        }
        await this.getTableData();
        await this.getJobList();
        await this.getEnterprise();
      } catch (err) {
        console.log("err", err);
      } finally {
        this.loading = false;
      }
    },
    async getTableData() {
      const res = await getPageList(this.queryParams);
      if (res.IsSuccess) {
        this.tableData = res.Result.Data;
      }
    },
    handleView(id) {
      this.$router.push(`/school/practice/track/view?id=${id}`);
    },
    async getJobList() {
      const res = await queryApplyJobCatelog({
        schoolId: this.queryParams.SchoolId
      });
      if (res.IsSuccess) {
        this.jobList = res.Result;
      }
    },
    async getEnterprise() {
      const res = await getEnterpriseList();
      if (res.IsSuccess) {
        this.enterpriseList = res.Result;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.header-box {
  display: flex;
  justify-content: space-between;
}
.table {
  overflow-x: auto;
}
</style>
